import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { CampaignContainer } from "../styles/campaign";
import { CampaignVideoContainer } from "../styles/campaign";

import { MdArrowForward, MdChevronLeft, MdDownload } from "react-icons/md";

import { useAtom } from "jotai";
import { selectedCampaignAtom, selectedCampaignItemAtom } from "../store/store";

import { MdArrowDownward } from "react-icons/md";

import Cnavbar from "../views/Cnavbar";
import { ButtonContainer } from "../styles/styles";

import Stories from "stories-react";
import "stories-react/dist/index.css";

import DOMPurify from "dompurify";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

import { useLocation } from "react-router-dom";

import LuisitoC from "../assets/images/LuisitoC_Cuadrado.jpg";
import CocaCola from "../assets/images/CocaCola_Cuadrado.jpg";
import Sainz from "../assets/images/Sainz_Cuadrado.jpg";
import Yoplait_Completa from "../assets/images/Yoplait_Completa.jpg";
import Bimbo_Completa from "../assets/images/Bimbo_Completa.jpg";
import Chokokrispis from "../assets/images/Chokokrispis.jpg";


import snickers from "../assets/video/snickers.mp4";
import sofi_fer from "../assets/video/sofi_fer.mp4";
import doritos from "../assets/video/doritos.mp4";
import mel_de_haene from "../assets/video/mel_de_haene.mp4";
import ana_tena from "../assets/video/ana_tena.mp4";
import mafer_rocha from "../assets/video/mafer_rocha.mp4";
import amairani_garza from "../assets/video/amairani_garza.mp4";
import paola_rojas from "../assets/video/paola_rojas.mp4";
import capi_perez from "../assets/video/capi_perez.mp4";
import karol_sevilla from "../assets/video/karol_sevilla.mp4";
import hermanos_skabeche from "../assets/video/hermanos_skabeche.mp4";

import granmalo_luisito from "../assets/video/granmalo_luisito.mp4";
import fasfu_luisito from "../assets/video/fasfu_luisito.mp4";
import cocacola_andrea_bentley from "../assets/video/cocacola_andrea_bentley.mp4";
import cocacola_ama_blitz from "../assets/video/cocacola_ama_blitz.mp4";
import cocacola_cherry_dii from "../assets/video/cocacola_cherry_dii.mp4";
import ines_saenz from "../assets/video/ines_saenz.mp4";


import chokokrispis_marj from "../assets/video/20240603_marjorielm123_Kellogs.mp4";
import chokokrispis_marj_2 from "../assets/video/20240603_marjorlielm123_Kellogs_2.mp4";
import chokokrispis_habanazoe from "../assets/video/20240608_habanazoe_Kellogs.mp4";
import chokokrispis_habanazoe_2 from "../assets/video/20240606_habanazoe_Kellogs_2.mp4";
import chokokrispis_janifukui from "../assets/video/20240609_janifukui_Kellogs.mp4";
import chokokrispis_pakocedillo from "../assets/video/20240609_pakocedillo_Kellogs.mp4";



import littlebaby_lia from "../assets/video/littlebaby_lia.mp4";
import sharon_pop_oficial from "../assets/video/sharon_pop_oficial.mp4";
import jorgelozanoh from "../assets/images/20230519_jorgelozanoh.jpg";
import rominaycamila from "../assets/images/20230519_rominaycamila.jpg";
import angelatasyblog_empanizado from "../assets/video/20230504_angelatasyblog_empanizado.mp4";
import manuelaguiarpeon_empanizado from "../assets/video/20230509_manuelaguiarpeon_empanizado.mp4";
import marchenacindy_empanizado from "../assets/video/20230517_marchenacindy_empanizado.mp4";
import danielazzuara_empanizado from "../assets/video/20230518_danielazzuara_empanizado.mp4";
import fernada_samano_empanizado_img from "../assets/images/20230505_fernada_samano_empanizado.jpg";
import manuelaguiarpeon_empanizado_img from "../assets/images/20230505_manuelaguiarpeon_empanizado.png";
import danielazzuara_empanizado_img from "../assets/images/20230511_danielazzuara_empanizado.png";

const campaigns = [
  {
    id: 1,
    video: snickers,
    date: "31/03/2022",
    name: "Snickers",
    slug: "snickers",

    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/snickers.mp4",
        name: "Snickers",
        video: snickers,
        date: "31/03/2022",
        text: "La cuenta de Instagram de Snickers en México es muestra de la falta de entendimiento de lo que es la publicidad transparente e identificable. Documentamos cómo esta marca de chocolates compartió en su cuenta de Instagram oficial publicidad engañosa de los influencers Nabile Ahumada (@nabileahumada, 358.0 mil seguidores), Pico Navarro (@piconavarrobeats, 16.4 mil seguidores) y Mauricio Olmedo (@elmauri, 20.6 mil seguidores) el 31 de marzo del 2022.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de Snickers tomada el 31.03.2022",
      },
    ],
  },
  {
    id: 2,
    video: mafer_rocha,
    date: "24/10/2021",
    name: "Helados Nestlé",
    slug: "helados_nestle",

    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/mafer_rocha.mp4",
        name: "Helados Nestlé",
        video: mafer_rocha,
        date: "24/10/2021",
        text: "En el estudio documentamos una campaña de publicidad engañosa a través de influencers por parte de HELADOS NESTLÉ en México. En este video la influencer Mafer Rocha, quien se hace llamar “Streamer”, quería crear empatía con la marca el 24 de octubre del del 2021 en su cuenta de Tik Tok (1.6 millones de seguidores).",
        footnote:
          "Grabación de pantalla de la cuenta de TikTok de Mafer Rocha tomada el 24.10.2021",
      },
    ],
  },
  {
    id: 3,
    video: karol_sevilla,
    date: "23/07/2021",
    name: "Choco Krispis",
    slug: "choco_krispis",

    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/karol_sevilla.mp4",
        name: "Choco Krispis",
        video: karol_sevilla,
        date: "23/07/2021",
        text: "La mega influencer Karol Sevilla (@karolsevillaokay, 26.5 millones de seguidores en TikTok) compuso una canción para el cereal Choco Krispis de la empresa multinacional de cereales Kellogg’s. En TikTok la coreografía y la canción hizo mucho eco, tanto que hay una docena de videos replicando la coreografía y canción de la influencer. Lo bueno: etiquetó el video con #Ad.",
        footnote:
          "Grabación de pantalla de la cuenta de TikTok de Mafer Rocha tomada el 23.07.2021",
      },
    ],
  },
  {
    id: 4,
    video: mel_de_haene,
    date: "16/09/2021",
    name: "Danonino",
    slug: "danonino",

    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/mel_de_haene.mp4",
        name: "Danonino",
        video: mel_de_haene,
        date: "16/09/2021",
        text: "La marca Danonino del gigante multinacional agroalimentario de origen francés Grupo Danone es uno de los peores ejemplos de publicidad engañosa a través de influencers en México. El 16 de septiembre del 2021 la empresa compartió un video de la mamá influencer Mel de Haene (@melsinletraschiquitas, @174 mil seguidores), quien, además, para sus fines comerciales, expone la privacidad de su hijo.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de Mel de Haene tomada el 16.09.2021",
      },
    ],
  },
  {
    id: 5,
    video: sofi_fer,
    date: "15/03/2022",
    name: "Krispy Kream",
    slug: "krispykream",

    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/sofi_fer.mp4",
        name: "Krispy Kream",
        video: sofi_fer,
        date: "15/03/2022",
        text: "KrispyKream, junto con Distroller (marca de juguetes), hicieron una campaña engañosa de publicidad, utilizando influencers infantiles. La influencer Sofí Fer (@juguetesdesofifer, 20.9 mil seguidores) cuya cuenta está “manejada por papá @tapiajr” invitó a sus seguidores a comprar su paquete de donas de la edición Distroller con sus amigos de KrispyKream.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de Sofifer tomada el 15.03.2022",
      },
    ],
  },
  {
    id: 6,
    video: ana_tena,
    name: "McDonald´s",
    slug: "mcdonalds",

    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/ana_tena.mp4",
        name: "McDonald´s",
        video: ana_tena,
        date: "31/03/2022",
        text: "McDonald´s no se queda atrás en esta tendencia de marketing. En su campaña engañosa de publicidad conocida como “combinación perfecta”, la influencer Ana Tena (6.5 millones de seguidores) en su cuenta de TikTok hace alusión a una combinación perfecta entre ella y su novio, y la compra con la combinación perfecta de McDonalds: una hamburguesa de pollo con pan hecho a base de papá.",
        footnote:
          "Video Grabación de pantalla de la cuenta de Instagram de Ana Tena tomada el 1.04.2022",
      },
    ],
  },
  {
    id: 7,
    video: hermanos_skabeche,
    date: "08/11/2022",
    name: "Fanta",
    slug: "fanta",

    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/hermanos_skabeche.mp4",
        name: "Fanta",
        video: hermanos_skabeche,
        date: "08/11/2022",
        text: "Fanta a través de los influencers hermanos Bryan Skabeche (@bryan_skabeche, 4.0 millones de seguidores) y Eddy Skabeche (@eddy_skabeche, 2.6 millones de seguidores) promocionó su nuevo refresco. Los hermanos influencers pedían a sus seguidores hacer una dinámica con la marca para que les regalaran una Fanta.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de Fanta tomada el 08.11.2022",
      },
    ],
  },
  {
    id: 8,
    video: doritos,
    date: "21/11/2022",
    name: "Doritos",
    slug: "doritos",

    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/doritos.mp4",
        name: "Doritos",
        video: doritos,
        date: "21/11/2022",
        text: "La fritura Doritos se vale de festivales e influencers para posicionar sus productos frente a adolescentes y jóvenes.  En el Corona Capital, diversos influencers como Miranda Ibañez (@miranda_ibanez, 1.2 millones de seguidores) o Mauricio Abad (@soymauricioabad, 579 mil seguidores) presumieron su paquete de Doritos en el festival e invitaban a sus seguidores a visitar el stand de la marca en el Corona Capital.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de Doritos tomada el 21.11.2022",
      },
    ],
  },
  {
    id: 9,
    prevId: 3,
    nextId: 2,
    image: LuisitoC,
    name: "Luisito Comunica",
    slug: "luisito",
    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/granmalo_luisito.mp4",
        name: "Luisito Comunica",
        video: granmalo_luisito,
        date: "05/01/2022",
        text: "Luisito Comunica, con 33.4 millones de seguidores en Instagram y 40 millones de suscriptores en YouTube, promociona a menores de manera engañosa su propia bebida alcohólica violando el Reglamento de la Ley General de Salud en Materia de Publicidad. Este influencer es una máquina de publicidad engañosa.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de Luisito Comunica tomada el 05.01.2022",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/fasfu_luisito.mp4",
        name: "Luisito Comunica",
        video: granmalo_luisito,
        date: "07/04/2023",
        text: "Luisito Comunica, con 33.4 millones de seguidores en Instagram y 40 millones de suscriptores en YouTube, promociona a menores de manera engañosa su propia bebida alcohólica violando el Reglamento de la Ley General de Salud en Materia de Publicidad. Este influencer es una máquina de publicidad engañosa.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de Luisito Comunica tomada el 07.04.2023",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/pillofon_luisito.mp4",
        name: "Luisito Comunica",
        video: granmalo_luisito,
        date: "07/04/2023",
        text: "Luisito Comunica, con 33.4 millones de seguidores en Instagram y 40 millones de suscriptores en YouTube, promociona a menores de manera engañosa su propia bebida alcohólica violando el Reglamento de la Ley General de Salud en Materia de Publicidad. Este influencer es una máquina de publicidad engañosa.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de Luisito Comunica tomada el 07.04.2023",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/nfl_luisito.mp4",
        name: "Luisito Comunica",
        video: granmalo_luisito,
        date: "07/04/2023",
        text: "Luisito Comunica, con 33.4 millones de seguidores en Instagram y 40 millones de suscriptores en YouTube, promociona a menores de manera engañosa su propia bebida alcohólica violando el Reglamento de la Ley General de Salud en Materia de Publicidad. Este influencer es una máquina de publicidad engañosa.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de Luisito Comunica tomada el 07.04.2023",
      },
    ],
  },
  {
    id: 10,
    prevId: 1,
    nextId: 3,
    image: CocaCola,
    name: "Coca Cola",
    slug: "coca_cola",
    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/cocacola_ama_blitz.mp4",
        name: "Coca Cola",
        video: cocacola_ama_blitz,
        date: "12.02.2023",
        text: "La refresquera multi-nacional hace una colaboración con la cantante habla-hispana del momento “Rosalía”. Para promocionar los refrescos de esta colaboración, Coca-Cola utiliza a decenas de influencers. La dinámica es la misma, las y los influencers reciben “un regalito” por parte que Coca-Cola que incluye los refrescos edición limitada en una caja, lentes y audífonos. Las y los influencers etiquetan la cuenta de Coca Cola México en Instagram y utilizan etiquetas insignia.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de Amablitz tomada el 12.02.2023",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/cocacola_andrea_bentley.mp4",
        name: "Coca Cola",
        video: cocacola_andrea_bentley,
        date: "28.02.2023",
        text: "La refresquera multi-nacional hace una colaboración con la cantante habla-hispana del momento “Rosalía”. Para promocionar los refrescos de esta colaboración, Coca-Cola utiliza a decenas de influencers. La dinámica es la misma, las y los influencers reciben “un regalito” por parte que Coca-Cola que incluye los refrescos edición limitada en una caja, lentes y audífonos. Las y los influencers etiquetan la cuenta de Coca Cola México en Instagram y utilizan etiquetas insignia.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de CherryDii tomada el 28.02.2023",
      },
    ],
  },
  {
    id: 11,
    prevId: 4,
    nextId: 2,
    image: Yoplait_Completa,
    name: "Yoplait",
    slug: "yoplait",
    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/yoplait1.mp4",
        name: "Yoplait",
        video: sharon_pop_oficial,
        date: "19.05.2023",
        text: "La multinacional Yoplait lanzó una campaña para su nueva línea de yogurt griego bebible. En dicha campaña aparentemente utiliza influencers infantiles. Las y los niños quienes aparecen rodeados del kit de productos de Yoplait, presumiendo que se trata de un yogurt reducido en grasa, sin azúcares añadidos, rico y con aporte de proteína. Uno de los videos que compartimos en el observatorio es de la cuenta @liafranco_oficial (314 seguidores en Instagram). Esta influencer infantil se describe como “Artista, Modelo y Actriz” y menciona que la cuenta es manejada por su mami. Además de promocionar Yoplait, esta influencer infantil hace publicidad constante para juguetes y ropa. En nuestro <a class='underline' href='https://www.tec-check.com.mx/wp-content/uploads/2023/04/Informe-ChatarraInfluencer-Tec-Check-Poder-del-Consumidor.pdf' target='_blank'>informe de #ChatarraInfluencer</a> documentamos más casos de influencers infantiles. No solamente este tipo de publicidad usando niñas y niños es ilegal, también es una vergüenza ver cómo sus papás aprovechan a sus hijos e hijas para fines económicos.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de sharon_pop_oficial tomada el 19.05.2023",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/yoplait2.mp4",
        name: "Yoplait",
        video: littlebaby_lia,
        date: "19.05.2023",
        text: "La multinacional Yoplait lanzó una campaña para su nueva línea de yogurt griego bebible. En dicha campaña aparentemente utiliza influencers infantiles. Las y los niños quienes aparecen rodeados del kit de productos de Yoplait, presumiendo que se trata de un yogurt reducido en grasa, sin azúcares añadidos, rico y con aporte de proteína. Uno de los videos que compartimos en el observatorio es de la cuenta @liafranco_oficial (314 seguidores en Instagram). Esta influencer infantil se describe como “Artista, Modelo y Actriz” y menciona que la cuenta es manejada por su mami. Además de promocionar Yoplait, esta influencer infantil hace publicidad constante para juguetes y ropa. En nuestro <a class='underline' href='https://www.tec-check.com.mx/wp-content/uploads/2023/04/Informe-ChatarraInfluencer-Tec-Check-Poder-del-Consumidor.pdf' target='_blank'>informe de #ChatarraInfluencer</a> documentamos más casos de influencers infantiles. No solamente este tipo de publicidad usando niñas y niños es ilegal, también es una vergüenza ver cómo sus papás aprovechan a sus hijos e hijas para fines económicos.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de littlebaby_lia y liafranco_oficial tomada el 19.05.2023",
      },
      {
        type: "image",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20230519_jorgelozanoh.jpg",
        name: "Yoplait",
        video: jorgelozanoh,
        date: "19.05.2023",
        text: "La multinacional Yoplait lanzó una campaña para su nueva línea de yogurt griego bebible. En dicha campaña aparentemente utiliza influencers infantiles. Las y los niños quienes aparecen rodeados del kit de productos de Yoplait, presumiendo que se trata de un yogurt reducido en grasa, sin azúcares añadidos, rico y con aporte de proteína. Uno de los videos que compartimos en el observatorio es de la cuenta @liafranco_oficial (314 seguidores en Instagram). Esta influencer infantil se describe como “Artista, Modelo y Actriz” y menciona que la cuenta es manejada por su mami. Además de promocionar Yoplait, esta influencer infantil hace publicidad constante para juguetes y ropa. En nuestro <a class='underline' href='https://www.tec-check.com.mx/wp-content/uploads/2023/04/Informe-ChatarraInfluencer-Tec-Check-Poder-del-Consumidor.pdf' target='_blank'>informe de #ChatarraInfluencer</a> documentamos más casos de influencers infantiles. No solamente este tipo de publicidad usando niñas y niños es ilegal, también es una vergüenza ver cómo sus papás aprovechan a sus hijos e hijas para fines económicos.",
        footnote:
          "Captura de pantalla de la cuenta de Instagram de jorgelozanoh tomada el 19.05.2023",
      },
      {
        type: "image",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20230519_rominaycamila.jpg",
        name: "Yoplait",
        video: rominaycamila,
        date: "19.05.2023",
        text: "La multinacional Yoplait lanzó una campaña para su nueva línea de yogurt griego bebible. En dicha campaña aparentemente utiliza influencers infantiles. Las y los niños quienes aparecen rodeados del kit de productos de Yoplait, presumiendo que se trata de un yogurt reducido en grasa, sin azúcares añadidos, rico y con aporte de proteína. Uno de los videos que compartimos en el observatorio es de la cuenta @liafranco_oficial (314 seguidores en Instagram). Esta influencer infantil se describe como “Artista, Modelo y Actriz” y menciona que la cuenta es manejada por su mami. Además de promocionar Yoplait, esta influencer infantil hace publicidad constante para juguetes y ropa. En nuestro <a class='underline' href='https://www.tec-check.com.mx/wp-content/uploads/2023/04/Informe-ChatarraInfluencer-Tec-Check-Poder-del-Consumidor.pdf' target='_blank'>informe de #ChatarraInfluencer</a> documentamos más casos de influencers infantiles. No solamente este tipo de publicidad usando niñas y niños es ilegal, también es una vergüenza ver cómo sus papás aprovechan a sus hijos e hijas para fines económicos.",
        footnote:
          "Captura de pantalla de la cuenta de Instagram de rominaycamila tomada el 19.05.2023",
      },
    ],
  },
  {
    id: 12,
    prevId: 1,
    nextId: 3,
    image: Bimbo_Completa,
    name: "Bimbo",
    slug: "bimbo",
    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/bimbo1.mp4",
        name: "Bimbo",
        video: angelatasyblog_empanizado,
        date: "04.05.2023",
        text: "La línea de empanizadores “Cero Cero”, “Crujiente” y “Clásico” de Bimbo, promocionada engañosamente por influencers “foodies” y “fitness”, no sólo aporta un exceso de calorías NO indispensables (como sus sellos lo indican) sino también contiene aditivos tóxicos. Esto último ha sido documentado por El Poder del Consumidor en sus radiografías. Las y los influencers que participan en esta campaña incitan a sus seguidores a preparar la misma receta “saludable”, pues utilizan verduras como base para el empanizado y acompañan con ensalada. No es la primera campaña engañosa de influencers que Bimbo pone en marcha. En el <a class='underline' href='https://www.tec-check.com.mx/wp-content/uploads/2023/04/Informe-ChatarraInfluencer-Tec-Check-Poder-del-Consumidor.pdf' target='_blank'>informe #ChatarraInfluencer</a> reportamos la campaña #RutaDelaHamburguesa sobre su pan para hacer este tipo de platillos.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de angelatasyblog tomada el 04.05.2023",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/bimbo2.mp4",
        name: "Bimbo",
        video: manuelaguiarpeon_empanizado,
        date: "09.05.2023",
        text: "La línea de empanizadores “Cero Cero”, “Crujiente” y “Clásico” de Bimbo, promocionada engañosamente por influencers “foodies” y “fitness”, no sólo aporta un exceso de calorías NO indispensables (como sus sellos lo indican) sino también contiene aditivos tóxicos. Esto último ha sido documentado por El Poder del Consumidor en sus radiografías. Las y los influencers que participan en esta campaña incitan a sus seguidores a preparar la misma receta “saludable”, pues utilizan verduras como base para el empanizado y acompañan con ensalada. No es la primera campaña engañosa de influencers que Bimbo pone en marcha. En el <a class='underline' href='https://www.tec-check.com.mx/wp-content/uploads/2023/04/Informe-ChatarraInfluencer-Tec-Check-Poder-del-Consumidor.pdf' target='_blank'>informe #ChatarraInfluencer</a> reportamos la campaña #RutaDelaHamburguesa sobre su pan para hacer este tipo de platillos.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de manuelaguiarpeon tomada el 09.05.2023",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/bimbo3.mp4",
        name: "Bimbo",
        video: marchenacindy_empanizado,
        date: "17.05.2023",
        text: "La línea de empanizadores “Cero Cero”, “Crujiente” y “Clásico” de Bimbo, promocionada engañosamente por influencers “foodies” y “fitness”, no sólo aporta un exceso de calorías NO indispensables (como sus sellos lo indican) sino también contiene aditivos tóxicos. Esto último ha sido documentado por El Poder del Consumidor en sus radiografías. Las y los influencers que participan en esta campaña incitan a sus seguidores a preparar la misma receta “saludable”, pues utilizan verduras como base para el empanizado y acompañan con ensalada. No es la primera campaña engañosa de influencers que Bimbo pone en marcha. En el <a class='underline' href='https://www.tec-check.com.mx/wp-content/uploads/2023/04/Informe-ChatarraInfluencer-Tec-Check-Poder-del-Consumidor.pdf' target='_blank'>informe #ChatarraInfluencer</a> reportamos la campaña #RutaDelaHamburguesa sobre su pan para hacer este tipo de platillos.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de marchenacindy tomada el 17.05.2023",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/bimbo4.mp4",
        name: "Bimbo",
        video: danielazzuara_empanizado,
        date: "18.05.2023",
        text: "La línea de empanizadores “Cero Cero”, “Crujiente” y “Clásico” de Bimbo, promocionada engañosamente por influencers “foodies” y “fitness”, no sólo aporta un exceso de calorías NO indispensables (como sus sellos lo indican) sino también contiene aditivos tóxicos. Esto último ha sido documentado por El Poder del Consumidor en sus radiografías. Las y los influencers que participan en esta campaña incitan a sus seguidores a preparar la misma receta “saludable”, pues utilizan verduras como base para el empanizado y acompañan con ensalada. No es la primera campaña engañosa de influencers que Bimbo pone en marcha. En el <a class='underline' href='https://www.tec-check.com.mx/wp-content/uploads/2023/04/Informe-ChatarraInfluencer-Tec-Check-Poder-del-Consumidor.pdf' target='_blank'>informe #ChatarraInfluencer</a> reportamos la campaña #RutaDelaHamburguesa sobre su pan para hacer este tipo de platillos.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de danielazzuara tomada el 18.05.2023",
      },
      {
        type: "image",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20230505_manuelaguiarpeon_empanizado.png",
        name: "Bimbo",
        video: manuelaguiarpeon_empanizado_img,
        date: "09.05.2023",
        text: "La línea de empanizadores “Cero Cero”, “Crujiente” y “Clásico” de Bimbo, promocionada engañosamente por influencers “foodies” y “fitness”, no sólo aporta un exceso de calorías NO indispensables (como sus sellos lo indican) sino también contiene aditivos tóxicos. Esto último ha sido documentado por El Poder del Consumidor en sus radiografías. Las y los influencers que participan en esta campaña incitan a sus seguidores a preparar la misma receta “saludable”, pues utilizan verduras como base para el empanizado y acompañan con ensalada. No es la primera campaña engañosa de influencers que Bimbo pone en marcha. En el <a class='underline' href='https://www.tec-check.com.mx/wp-content/uploads/2023/04/Informe-ChatarraInfluencer-Tec-Check-Poder-del-Consumidor.pdf' target='_blank'>informe #ChatarraInfluencer</a> reportamos la campaña #RutaDelaHamburguesa sobre su pan para hacer este tipo de platillos.",
        footnote:
          "Campura de pantalla de la cuenta de Instagram de manuelaguiarpeon tomada el 09.05.2023",
      },
      {
        type: "image",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20230511_danielazzuara_empanizado.png",
        name: "Bimbo",
        video: danielazzuara_empanizado_img,
        date: "11.05.2023",
        text: "La línea de empanizadores “Cero Cero”, “Crujiente” y “Clásico” de Bimbo, promocionada engañosamente por influencers “foodies” y “fitness”, no sólo aporta un exceso de calorías NO indispensables (como sus sellos lo indican) sino también contiene aditivos tóxicos. Esto último ha sido documentado por El Poder del Consumidor en sus radiografías. Las y los influencers que participan en esta campaña incitan a sus seguidores a preparar la misma receta “saludable”, pues utilizan verduras como base para el empanizado y acompañan con ensalada. No es la primera campaña engañosa de influencers que Bimbo pone en marcha. En el <a class='underline' href='https://www.tec-check.com.mx/wp-content/uploads/2023/04/Informe-ChatarraInfluencer-Tec-Check-Poder-del-Consumidor.pdf' target='_blank'>informe #ChatarraInfluencer</a> reportamos la campaña #RutaDelaHamburguesa sobre su pan para hacer este tipo de platillos.",
        footnote:
          "Captura de pantalla de la cuenta de Instagram de danielazzuara tomada el 11.05.2023",
      },
      {
        type: "image",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20230505_fernada_samano_empanizado.jpg",
        name: "Bimbo",
        video: fernada_samano_empanizado_img,
        date: "05.05.2023",
        text: "La línea de empanizadores “Cero Cero”, “Crujiente” y “Clásico” de Bimbo, promocionada engañosamente por influencers “foodies” y “fitness”, no sólo aporta un exceso de calorías NO indispensables (como sus sellos lo indican) sino también contiene aditivos tóxicos. Esto último ha sido documentado por El Poder del Consumidor en sus radiografías. Las y los influencers que participan en esta campaña incitan a sus seguidores a preparar la misma receta “saludable”, pues utilizan verduras como base para el empanizado y acompañan con ensalada. No es la primera campaña engañosa de influencers que Bimbo pone en marcha. En el <a class='underline' href='https://www.tec-check.com.mx/wp-content/uploads/2023/04/Informe-ChatarraInfluencer-Tec-Check-Poder-del-Consumidor.pdf' target='_blank'>informe #ChatarraInfluencer</a> reportamos la campaña #RutaDelaHamburguesa sobre su pan para hacer este tipo de platillos.",
        footnote:
          "Captura de pantalla de la cuenta de Instagram de fernanda_samano tomada el 05.05.2023",
      },
    ],
  },
  {
    id: 5,
    prevId: 4,
    nextId: 1,
    image: Chokokrispis,
    name: "Choko Krispis",
    slug: "choko_krispis",
    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20240603_marjorielm123_Kellogs.mp4",
        name: "Choko Krispis",
        video: chokokrispis_marj,
        date: "03.06.2024",
        text: "En su afán de hacer adictos al azúcar a las próximas generaciones, ChocoKrispis de Kellogg's lanzó en junio 2024 su nuevo producto ultra procesado denominado “ChocoKrispis Boom” con una campaña publicitaria dirigida descaradamente dirigida a niños, niñas y adolescentes. ¿Su estrategia? Una invasión de influencers infantiles en Kidzania, un centro de entretenimiento y 'educación' infantil. Allí, figuras como Ana Paula Serrado (@el_mundo_con_pau), Anabel García (@regislandmx), Habana Zoé (@habana_zoe) y las hermanas Paulina y Ariadna (@ariadnepaulinaoficial) se aliaron económicamente con Kellogg's para promocionar engañosamente el nuevo cereal. Las y los influencers que asistieron al lanzamiento compartieron en historias y publicaciones de Instagram cómo comían este cereal, preparaban su propia barra de cereal con dulces y otros productos chatarra, jugaban en la estación de fábrica de cereal que Kidzania e interactuaban con imágenes del nuevo cereal hechas con inteligencia artificial. Como parte de la campaña de lanzamiento, Kellogs también envió “unboxings”. ¿El resultado? Niños bombardeados con publicidad engañosa que presenta a los Choco Krispis Boom como algo 'delicioso' y 'crunchy', cuando en realidad es una bomba de azúcar que equivale a casi 4 cucharadas de azúcar por plato, según la radiografía de El Poder del Consumidor. Las niñas y los niños son especialmente susceptibles a la influencia de roles que siguen (en este caso sus influencers), es decir son un blanco fácil para la mercadotécnica persuasiva. Con esta campaña, Kellogs y ChocoKrispis nos muestran que sus lineamientos publicitarios están enfocados en manipular a la población infantil y vulnerar su salud.",
        footnote:
          "Grabación de la cuenta de Instagram @marj.orielm123_, tomada el 03.06.2024",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20240603_marjorlielm123_Kellogs_2.mp4",
        name: "Choko Krispis",
        video: chokokrispis_marj_2,
        date: "03.06.2024",
        text: "En su afán de hacer adictos al azúcar a las próximas generaciones, ChocoKrispis de Kellogg's lanzó en junio 2024 su nuevo producto ultra procesado denominado “ChocoKrispis Boom” con una campaña publicitaria dirigida descaradamente dirigida a niños, niñas y adolescentes. ¿Su estrategia? Una invasión de influencers infantiles en Kidzania, un centro de entretenimiento y 'educación' infantil. Allí, figuras como Ana Paula Serrado (@el_mundo_con_pau), Anabel García (@regislandmx), Habana Zoé (@habana_zoe) y las hermanas Paulina y Ariadna (@ariadnepaulinaoficial) se aliaron económicamente con Kellogg's para promocionar engañosamente el nuevo cereal. Las y los influencers que asistieron al lanzamiento compartieron en historias y publicaciones de Instagram cómo comían este cereal, preparaban su propia barra de cereal con dulces y otros productos chatarra, jugaban en la estación de fábrica de cereal que Kidzania e interactuaban con imágenes del nuevo cereal hechas con inteligencia artificial. Como parte de la campaña de lanzamiento, Kellogs también envió “unboxings”. ¿El resultado? Niños bombardeados con publicidad engañosa que presenta a los Choco Krispis Boom como algo 'delicioso' y 'crunchy', cuando en realidad es una bomba de azúcar que equivale a casi 4 cucharadas de azúcar por plato, según la radiografía de El Poder del Consumidor. Las niñas y los niños son especialmente susceptibles a la influencia de roles que siguen (en este caso sus influencers), es decir son un blanco fácil para la mercadotécnica persuasiva. Con esta campaña, Kellogs y ChocoKrispis nos muestran que sus lineamientos publicitarios están enfocados en manipular a la población infantil y vulnerar su salud.",
        footnote:
          "Grabación de la cuenta de Instagram @marj.orielm123_, tomada el 03.06.2024",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20240608_habanazoe_Kellogs.mp4",
        name: "Choko Krispis",
        video: chokokrispis_habanazoe,
        date: "06.06.2024",
        text: "En su afán de hacer adictos al azúcar a las próximas generaciones, ChocoKrispis de Kellogg's lanzó en junio 2024 su nuevo producto ultra procesado denominado “ChocoKrispis Boom” con una campaña publicitaria dirigida descaradamente dirigida a niños, niñas y adolescentes. ¿Su estrategia? Una invasión de influencers infantiles en Kidzania, un centro de entretenimiento y 'educación' infantil. Allí, figuras como Ana Paula Serrado (@el_mundo_con_pau), Anabel García (@regislandmx), Habana Zoé (@habana_zoe) y las hermanas Paulina y Ariadna (@ariadnepaulinaoficial) se aliaron económicamente con Kellogg's para promocionar engañosamente el nuevo cereal. Las y los influencers que asistieron al lanzamiento compartieron en historias y publicaciones de Instagram cómo comían este cereal, preparaban su propia barra de cereal con dulces y otros productos chatarra, jugaban en la estación de fábrica de cereal que Kidzania e interactuaban con imágenes del nuevo cereal hechas con inteligencia artificial. Como parte de la campaña de lanzamiento, Kellogs también envió “unboxings”. ¿El resultado? Niños bombardeados con publicidad engañosa que presenta a los Choco Krispis Boom como algo 'delicioso' y 'crunchy', cuando en realidad es una bomba de azúcar que equivale a casi 4 cucharadas de azúcar por plato, según la radiografía de El Poder del Consumidor. Las niñas y los niños son especialmente susceptibles a la influencia de roles que siguen (en este caso sus influencers), es decir son un blanco fácil para la mercadotécnica persuasiva. Con esta campaña, Kellogs y ChocoKrispis nos muestran que sus lineamientos publicitarios están enfocados en manipular a la población infantil y vulnerar su salud.",
        footnote:
          "Grabación de la cuenta de Instagram @habana_zoe_, tomada el 06.06.2024",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20240606_habanazoe_Kellogs_2.mp4",
        name: "Choko Krispis",
        video: chokokrispis_habanazoe_2,
        date: "08.06.2024",
        text: "En su afán de hacer adictos al azúcar a las próximas generaciones, ChocoKrispis de Kellogg's lanzó en junio 2024 su nuevo producto ultra procesado denominado “ChocoKrispis Boom” con una campaña publicitaria dirigida descaradamente dirigida a niños, niñas y adolescentes. ¿Su estrategia? Una invasión de influencers infantiles en Kidzania, un centro de entretenimiento y 'educación' infantil. Allí, figuras como Ana Paula Serrado (@el_mundo_con_pau), Anabel García (@regislandmx), Habana Zoé (@habana_zoe) y las hermanas Paulina y Ariadna (@ariadnepaulinaoficial) se aliaron económicamente con Kellogg's para promocionar engañosamente el nuevo cereal. Las y los influencers que asistieron al lanzamiento compartieron en historias y publicaciones de Instagram cómo comían este cereal, preparaban su propia barra de cereal con dulces y otros productos chatarra, jugaban en la estación de fábrica de cereal que Kidzania e interactuaban con imágenes del nuevo cereal hechas con inteligencia artificial. Como parte de la campaña de lanzamiento, Kellogs también envió “unboxings”. ¿El resultado? Niños bombardeados con publicidad engañosa que presenta a los Choco Krispis Boom como algo 'delicioso' y 'crunchy', cuando en realidad es una bomba de azúcar que equivale a casi 4 cucharadas de azúcar por plato, según la radiografía de El Poder del Consumidor. Las niñas y los niños son especialmente susceptibles a la influencia de roles que siguen (en este caso sus influencers), es decir son un blanco fácil para la mercadotécnica persuasiva. Con esta campaña, Kellogs y ChocoKrispis nos muestran que sus lineamientos publicitarios están enfocados en manipular a la población infantil y vulnerar su salud.",
        footnote:
          "Grabación de la cuenta de Instagram @habana_zoe_, tomada el 08.06.2024",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20240609_janifukui_Kellogs.mp4",
        name: "Choko Krispis",
        video: chokokrispis_janifukui,
        date: "09.06.2024",
        text: "En su afán de hacer adictos al azúcar a las próximas generaciones, ChocoKrispis de Kellogg's lanzó en junio 2024 su nuevo producto ultra procesado denominado “ChocoKrispis Boom” con una campaña publicitaria dirigida descaradamente dirigida a niños, niñas y adolescentes. ¿Su estrategia? Una invasión de influencers infantiles en Kidzania, un centro de entretenimiento y 'educación' infantil. Allí, figuras como Ana Paula Serrado (@el_mundo_con_pau), Anabel García (@regislandmx), Habana Zoé (@habana_zoe) y las hermanas Paulina y Ariadna (@ariadnepaulinaoficial) se aliaron económicamente con Kellogg's para promocionar engañosamente el nuevo cereal. Las y los influencers que asistieron al lanzamiento compartieron en historias y publicaciones de Instagram cómo comían este cereal, preparaban su propia barra de cereal con dulces y otros productos chatarra, jugaban en la estación de fábrica de cereal que Kidzania e interactuaban con imágenes del nuevo cereal hechas con inteligencia artificial. Como parte de la campaña de lanzamiento, Kellogs también envió “unboxings”. ¿El resultado? Niños bombardeados con publicidad engañosa que presenta a los Choco Krispis Boom como algo 'delicioso' y 'crunchy', cuando en realidad es una bomba de azúcar que equivale a casi 4 cucharadas de azúcar por plato, según la radiografía de El Poder del Consumidor. Las niñas y los niños son especialmente susceptibles a la influencia de roles que siguen (en este caso sus influencers), es decir son un blanco fácil para la mercadotécnica persuasiva. Con esta campaña, Kellogs y ChocoKrispis nos muestran que sus lineamientos publicitarios están enfocados en manipular a la población infantil y vulnerar su salud.",
        footnote:
          "Grabación de la cuenta de Instagram @janifukui, tomada el 09.06.2024",
      },
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/20240609_pakocedillo_Kellogs.mp4",
        name: "Choko Krispis",
        video: chokokrispis_pakocedillo,
        date: "09.06.2024",
        text: "En su afán de hacer adictos al azúcar a las próximas generaciones, ChocoKrispis de Kellogg's lanzó en junio 2024 su nuevo producto ultra procesado denominado “ChocoKrispis Boom” con una campaña publicitaria dirigida descaradamente dirigida a niños, niñas y adolescentes. ¿Su estrategia? Una invasión de influencers infantiles en Kidzania, un centro de entretenimiento y 'educación' infantil. Allí, figuras como Ana Paula Serrado (@el_mundo_con_pau), Anabel García (@regislandmx), Habana Zoé (@habana_zoe) y las hermanas Paulina y Ariadna (@ariadnepaulinaoficial) se aliaron económicamente con Kellogg's para promocionar engañosamente el nuevo cereal. Las y los influencers que asistieron al lanzamiento compartieron en historias y publicaciones de Instagram cómo comían este cereal, preparaban su propia barra de cereal con dulces y otros productos chatarra, jugaban en la estación de fábrica de cereal que Kidzania e interactuaban con imágenes del nuevo cereal hechas con inteligencia artificial. Como parte de la campaña de lanzamiento, Kellogs también envió “unboxings”. ¿El resultado? Niños bombardeados con publicidad engañosa que presenta a los Choco Krispis Boom como algo 'delicioso' y 'crunchy', cuando en realidad es una bomba de azúcar que equivale a casi 4 cucharadas de azúcar por plato, según la radiografía de El Poder del Consumidor. Las niñas y los niños son especialmente susceptibles a la influencia de roles que siguen (en este caso sus influencers), es decir son un blanco fácil para la mercadotécnica persuasiva. Con esta campaña, Kellogs y ChocoKrispis nos muestran que sus lineamientos publicitarios están enfocados en manipular a la población infantil y vulnerar su salud.",
        footnote:
          "Grabación de la cuenta de Instagram @pako.cedillo, tomada el 09.06.2024",
      },
    ],
  },
];

function Campaign() {
  const [selectedCampaign, setSelectedCampaign] = useAtom(selectedCampaignAtom);
  const [selectedCampaignItem, setSelectedCampaignItem] = useAtom(
    selectedCampaignItemAtom
  );
  const [slug, setSlug] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const nextCampaign = () => {
    // @ts-ignore
    const campaign = campaigns[selectedCampaign.id % 12];

    //console.log(campaign.slug);

    navigate(`/campana/${campaign.slug}`, { replace: true });

    navigate(0);
  };

  // @ts-ignore
  const sanitizedData = html => ({
    __html: DOMPurify.sanitize(html, { ADD_ATTR: ["target"] }),
  });

  useEffect(() => {
    //console.log("location.pathname", location.pathname.split("/")[2]);

    setSlug(location.pathname.split("/")[2]);

    campaigns
      .filter(c => c.slug === location.pathname.split("/")[2])
      .map(c => setSelectedCampaign(c));
  }, [location]);

  return (
    <CampaignContainer>
      <Cnavbar />

      {/* <div className="absolute top-[80px] left-5 z-10">
        <Link
          to={{
            pathname: "/",
            hash: "#campaigns",
          }}
          className="hover:bg-gray-900/75 bg-black text-white h-full text-center  hover:opacity-75 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300 rounded-full"
        >
          <MdChevronLeft className="text-5xl text-white" />
          <span className="sr-only">Prev</span>
        </Link>
      </div> */}

      {/*
      // @ts-ignore */}
      {/* {selectedCampaign.items.map(i => ( */}
      <div className=" flex md:flex-row 2xl:mx-28 flex-col bg-black">
        {/*
          // @ts-ignore */}

        <div>
          {/* <CampaignVideoContainer>
            <video
              className="ml-auto pt-12 pb-[90px]  mr-auto h-full w-auto"
              loop
              playsInline
              controls
            >

              <source src={i.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </CampaignVideoContainer> */}
          {/*
          // @ts-ignore */}
          <div className="ml-auto pt-12 pb-[90px]  mr-auto h-screen w-auto">
            <Stories
              // @ts-ignore
              stories={selectedCampaign.items}
              pauseStoryWhenInActiveWindow={true}
              // @ts-ignore
              onStoryChange={index =>
                // @ts-ignore
                setSelectedCampaignItem(selectedCampaign.items[index])
              }
            />
          </div>
          <div className="">
            <p className="text-chinwhite text-center ml-auto mr-auto -mt-[80px] w-10/12 z-40 mx-5">
              {/*
            // @ts-ignore */}
              {selectedCampaignItem.footnote}
            </p>
            {/*
          // BUTTON SCROLL DOWN */}
            <div className="button-scroll-down grid mt-5 justify-items-center ">
              <a href="#campaign-text">
                <button className="animate-bounce text-center ml-auto mr-auto z-50 btn btn-circle md:hidden">
                  <MdArrowDownward />
                </button>
              </a>
            </div>
          </div>
        </div>

        <div id="campaign-text" className="md:w-2/3 bg-black py-20 px-10">
          {/*
          // @ts-ignore */}
          {/* <p className="text-xl text-left uppercase font-normal text-chinred"> */}
          {/*
            // @ts-ignore */}
          {/* {i.date}
            </p> */}
          {/*
          // @ts-ignore */}
          <p className="text-4xl text-left italic uppercase font-bold text-chinwhite mt-5">
            {/*
          // @ts-ignore */}
            {selectedCampaignItem.name}
          </p>
          {/*
          // @ts-ignore */}
          <p
            className="text-chinwhite text-justify mt-5"
            /*
            // @ts-ignore */
            dangerouslySetInnerHTML={sanitizedData(selectedCampaignItem.text)}
          >
            {/*
            // @ts-ignore */}
          </p>

          {/* ////BOTON SIGUIENTE//// */}
          <div className="flex justify-end">
            <div>
              <ButtonContainer onClick={() => nextCampaign()}>
                <div>
                  <p className="text-sm text-barlow text-end uppercase text-white">
                    Campaña
                  </p>
                  <p className="text-xl text-barlow text-end font-bold uppercase text-chinred italic">
                    Siguiente
                  </p>
                </div>
                <div className="btn btn-circle ml-2 text-[25px]">
                  <MdArrowForward />
                </div>
              </ButtonContainer>
            </div>
          </div>
          <div className="pt-10">
            <p className="text-white mt-10">Comparte esta campaña:</p>
            <div className="flex gap-10">
            <FacebookShareButton
              // @ts-ignore
              url={`https://chatarrainfluencer.mx/campana/${selectedCampaign.slug}`}
              quote={"#ChatarraInfluencer: Observatorio ciudadano "}
              hashtag="#chatarrainfluencer"
            >
                      
              <FacebookIcon size={32} round />
                    
            </FacebookShareButton>
            <TwitterShareButton
              // @ts-ignore
              url={`https://chatarrainfluencer.mx/campana/${selectedCampaign.slug}`}
              // @ts-ignore
              quote={"#ChatarraInfluencer: Observatorio ciudadano "}
              hashtag="#chatarrainfluencer"
            >
                
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            </div>
                
          </div>
        </div>
      </div>
      {/* ))} */}
    </CampaignContainer>
  );
}

export default Campaign;
